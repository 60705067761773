<template>
  <svg
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5 0C14.5784 0 0 14.5798 0 32.5C0 50.4202 14.5784 65 32.5 65C50.4216 65 65 50.4202 65 32.5C65 14.5798 50.4216 0 32.5 0ZM32.5 62.2917C16.0728 62.2917 2.70829 48.9272 2.70829 32.5C2.70829 16.0728 16.0728 2.70829 32.5 2.70829C48.9272 2.70829 62.2917 16.0728 62.2917 32.5C62.2917 48.9272 48.9272 62.2917 32.5 62.2917Z"
      fill="#263238"
    />
    <path
      d="M45.4201 31.3613L26.4619 19.1738C26.0466 18.9028 25.5097 18.8882 25.0812 19.1236C24.6448 19.3616 24.375 19.8166 24.375 20.3125V44.6875C24.375 45.1834 24.6448 45.6384 25.0812 45.8764C25.2822 45.9862 25.507 46.0417 25.7292 46.0417C25.9831 46.0417 26.2397 45.9702 26.4619 45.8261L45.4201 33.6386C45.8063 33.39 46.0417 32.9602 46.0417 32.5C46.0417 32.0398 45.8063 31.61 45.4201 31.3613ZM27.0833 42.2066V22.7934L42.1828 32.5L27.0833 42.2066Z"
      fill="#263238"
    />
  </svg>
</template>
<script>
export default {
  name: "PlayButton",
  data() {
    return {};
  },
  props: {
    fill: {
      default: "--color-primary",
    },
  },
};
</script>